import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./main.css";
import VOtpInput from "vue3-otp-input";
import Vue3Lottie from "vue3-lottie";

const app = createApp(App)

app.component('v-otp-input', VOtpInput);

app.use(Vue3Lottie)
app.use(store).use(router).mount('#app')
