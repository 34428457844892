import { createRouter, createWebHashHistory } from 'vue-router'
// import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: "Home",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Competition/Login.vue"),
  },
  {
    path: "/login",
    name: "CompetitionLogin",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Competition/Login.vue"),
  },
  {
    path: "/onboarding",
    name: "Onboarding",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Competition/Onboarding.vue"),
  },
  {
    path: "/404",
    name: "NotFound",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/404.vue"),
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
